<script>
export default {
	lang: 'shop',
	props: {
		option: Object,
		currency: String,
		selected: Boolean,
		showToAddressLine: Boolean,
		showCost: Boolean,
		messageItems: Array,
		withRadio: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		cMessageItems() {
			let items = []
			if (this.showToAddressLine) {
				items.push({ icon: 'mdi-map-marker', text: this.option.toAddressLine })
			}
			if (this.messageItems) {
				items.push(...this.messageItems)
			}
			if (this.option.message) {
				items.push({ icon: 'mdi-information', text: this.option.message })
			}
			items.forEach((item) => {
				item.text = Array.isArray(item.text) ? item.text : item.text ? [item.text] : null
			})
			return items.filter((item) => item.icon && item.text?.length)
		},
	},
}
</script>

<template>
	<div class="d-flex align-start delivery-option">
		<v-radio :value="option" class="" v-if="withRadio" />
		<div class="w100">
			<div class="d-flex align-start flex-column flex-sm-row justify-sm-space-between">
				<div>
					<div class="font-weight-bold font-2 delivery-option__title">
						{{ option.optionName }}
					</div>
					<div class="font-weight-bold font-1 pt-1" v-if="option.hasEta && option.etaMessage">
						{{ option.etaMessage }}
					</div>
				</div>
				<div v-if="option.hasCost && showCost" class="py-2 py-sm-0 pl-sm-3 d-flex align-center">
					<div v-if="option.discount" class="pr-2">
						<del class="grey--text">
							<PriceText :amount="option.cost" :currency="currency" />
						</del>
					</div>
					<div v-if="option.total > 0">
						<PriceText :amount="option.total" :currency="currency" class="font-2 font-weight-bold" />
					</div>
					<div v-else>
						<div class="rounded white--text success d-inline-block px-2 py-1 font-1">
							{{ 'Gratis' | lang }}
						</div>
					</div>
				</div>
			</div>
			<div class="pt-1" v-if="cMessageItems.length">
				<div v-for="(item, i) of cMessageItems" :key="i" class="py-1 d-flex align-start base">
					<v-icon small class="mr-1" color="base">{{ item.icon }}</v-icon>
					<div>
						<div v-for="(text, j) of item.text" :key="j" class="preline base">{{ text }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.delivery-option {
	.v-radio {
		margin-bottom: 0 !important;
	}
	&__title {
		line-height: 24px;
	}
}
</style>
