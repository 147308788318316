<script>
import { get } from 'vuex-pathify'
import { checkout } from '../components'

export default {
	lang: 'shop',
	metaInfo: {
		title: 'Checkout',
	},
	data() {
		return {
			checkout: {
				order: null,
				hash: null,
				stepsData: null,
				stepsKeys: null,
				nextStepKey: null,
				loadingStep: false,
				loadingSubmit: false,
				validation: {},
				stepViewData: {},
				stepSubmit: (...args) => this.stepSubmit(...args),
				gotoStep: (...args) => this.gotoStep(...args),
				catchApiErrors: (...args) => this.catchApiErrors(...args),
			},
		}
	},
	provide() {
		return {
			xcheckout: () => this.checkout,
		}
	},
	computed: {
		hash: get('route@params.hash'),
		stepKey() {
			return this.$route.params.stepKey || 'init'
		},
		stepExists() {
			return (
				this.stepKey == 'init' ||
				!this.checkout.stepsKeys ||
				this.checkout.stepsKeys.includes(this.stepKey)
			)
		},
	},
	watch: {
		stepExists(value) {
			if (!value) {
				this.$router.push({ name: 'home' })
			}
		},
		stepKey(value) {
			if (value && this.stepExists) {
				this.loadStep()
			}
		},
	},
	methods: {
		initCheckout() {
			this.checkout.loadingStep = true
			this.$shopApi.get({
				url: '/checkout/init',
				query: {
					...this.$route.query,
					ce: navigator.cookieEnabled,
				},
				onSuccess: ({ data: { checkout, jumpTo } }) => {
					this.$eventer().trigger('checkout:init', {
						order: checkout.order,
					})
					this.$trackers.pageView()
					this.$router.replace(this.getStepRoute(jumpTo, checkout.hash))
				},
				onError: (response) => this.catchApiErrors(response),
			})
		},
		loadStep() {
			this.checkout.loadingStep = true
			return this.$shopApi.get({
				url: `/checkout/step/${this.stepKey}`,
				query: { hash: this.hash },
				loading: (v) => (this.checkout.loadingStep = v),
				onSuccess: ({ data, options }) => {
					let { checkout, stepViewData, jumpTo } = data

					if (jumpTo) {
						options.abort()
						this.gotoStep(data.jumpTo)
					} else {
						this.$assignDeep(this.checkout, checkout)
						this.checkout.stepViewData = {}
						this.$assignDeep(this.checkout.stepViewData, stepViewData)
						this.checkout.validation = {}

						this.$eventer().trigger('checkout:step-loaded', {
							stepKey: this.stepKey,
							order: checkout.order,
						})

						this.$nextTick(() => {
							this.checkout.loadingStep = false
							this.trackPageView()
						})
					}
				},
				onError: (response) => this.catchApiErrors(response),
			})
		},
		stepSubmit(payload) {
			return this.$shopApi.post({
				url: `/checkout/step/${this.stepKey}`,
				query: { hash: this.hash },
				data: payload || {},
				loading: (v) => (this.checkout.loadingSubmit = v),
				onValidation: ({ validation }) => (this.checkout.validation = validation),
				onSuccess: async ({ data }) => {
					await this.$eventer().trigger('checkout:step-submit', {
						stepKey: this.stepKey,
						order: data.checkout.order,
					})
					if (data.jumpTo) {
						this.gotoStep(data.jumpTo)
					} else {
						this.$store.set('cart/order', null)
					}
				},
				onError: (response) => this.catchApiErrors(response),
			})
		},
		catchApiErrors({ data, message, options }) {
			let route = null
			if (message?.code == 'invalid_checkout_hash') {
				if (data.orderId) {
					route = {
						name: 'user.order-detail',
						params: { id: data.orderId },
					}
				} else {
					route = { name: 'home' }
				}
			} else if (message?.code == 'cart_empty') {
				route = { name: 'home' }
				this.$store.set('cart/order', null)
			} else if (message?.code == 'cart_items_removed') {
				route = { name: 'home' }
				this.$store.set('cart/order', null)
			} else if (data.invalidStepKey) {
				// } else if (message.code == 'invalidated_step') {
				route = this.getStepRoute(data.invalidStepKey)
			}
			if (route) {
				options.abort()
				this.$router.push(route)
				// location.href = this.$router.resolve(route).href
				return true
			}
			return false
		},
		getStepRoute(stepKey, hash) {
			return {
				name: 'checkout.step',
				params: {
					stepKey,
					hash: hash || this.hash,
				},
			}
		},
		gotoStep(stepKey) {
			return this.$router.push(this.getStepRoute(stepKey))
		},
		trackPageView() {
			let location = this.$router.resolve({
				name: this.$route.name,
				params: {
					...this.$route.params,
					hash: '__HASH__',
				},
				query: this.$route.query,
			}).href
			this.$meta().refresh()
			this.$trackers.pageView({ location })
		},
	},
	mounted() {
		if (this.stepKey == 'init') this.initCheckout()
		else this.loadStep()
	},
}
</script>

<template>
	<component v-if="stepExists" :is="`CheckoutStep-${stepKey}`" />
</template>
