<script>
import addressHelperMixin from '@/v-shop/mixins/address-helper-mixin.js'
export default {
	lang: 'shop',
	inject: ['xcheckout'],
	mixins: [addressHelperMixin],
	data() {
		return {
			address: null,
			receiver: {
				firstname: null,
				lastname: null,
				idNumber: null,
			},
			dialog: false,
			selection: null,
			loadingOptions: false,
			options: null,
			countriesFilter: null,
		}
	},
	computed: {
		checkout() {
			return this.xcheckout()
		},
		currency() {
			return this.checkout.order.currency.sign
		},
		canSubmit() {
			return !!(
				this.selection &&
				this.receiver.firstname &&
				this.receiver.lastname &&
				this.receiver.idNumber
			)
		},
		allOptions() {
			return this.options ? Object.values(this.options).flat() : []
		},
		hasDeliveryOptions() {
			return this.allOptions.length > 0
		},
	},
	methods: {
		async onAddressSelected(address) {
			this.address = address
			this.searchDeliveryOptions()
		},
		async searchDeliveryOptions() {
			if (!this.address) return
			await this.$shopApi.post({
				url: '/checkout/delivery/calculate',
				data: { address: this.address },
				query: { hash: this.$route.params.hash },
				loading: (v) => (this.loadingOptions = v),
				onSuccess: ({ data }) => {
					this.options = data.options
					let { receiver, deliveryOption } = this.checkout.stepsData?.delivery?.payload || {}
					if (receiver) {
						this.receiver = receiver
					}
					if (deliveryOption) {
						this.selection = this.allOptions.find((option) => option.hash == deliveryOption.hash)
						if (this.selection.methodKey == 'PickupPoint') {
							this.options.PickupPoint.sort((a, b) =>
								a == this.selection ? -1 : b == this.selection ? 1 : 0
							)
						}
					} else {
						this.selection = this.options.Shipping?.[0] || this.options.PickupPoint?.[0] || null
					}
				},
			})
		},
		async submit() {
			this.checkout.stepSubmit({
				clientAddress: this.address,
				deliveryOption: this.selection,
				receiver: this.receiver,
			})
		},
	},
	eventsListeners: {
		'checkout:step-loaded'() {
			let { availableCountriesIds } = this.checkout.stepViewData || {}
			if (availableCountriesIds?.length) {
				this.countriesFilter = (country) => availableCountriesIds.some((x) => x == country.id)
			} else {
				this.countriesFilter = null
			}

			let { clientAddress } = this.checkout.stepsData?.delivery?.payload || {}
			if (clientAddress) this.onAddressSelected(clientAddress)
			else this.dialog = true
		},
	},
	watch: {
		dialog(value) {
			if (!value && !this.address) {
				setTimeout(() => {
					this.dialog = true
				}, 500)
			}
		},
	},
}
</script>

<template>
	<CheckoutStepLayout :title="$lang('Elige cómo recibir tu compra')" step="delivery">
		<Validator :validation="checkout.validation">
			<div>
				<div v-if="address">
					<CardLayout>
						<div class="font-weight-bold">{{ 'Domicilio' | lang }}</div>
						<div>{{ makeAddressLine(address) }}</div>
						<Button color="link" small @click.prevent.stop="dialog = true" class="mt-3">
							{{ 'Cambiar domicilio' | lang }}
						</Button>
					</CardLayout>
					<div v-if="loadingOptions" class="w100 pt-2">
						<v-progress-linear indeterminate class="mb-2" />
						<div>{{ 'Cargando opciones de envío' | lang }}</div>
					</div>
					<div class="pb-3" v-else-if="hasDeliveryOptions">
						<v-radio-group v-model="selection" class="font-1 ma-0">
							<template v-if="options.Shipping && options.Shipping.length">
								<div class="py-3 font-2 d-flex align-center">
									<v-icon color="black" class="mr-2">mdi-truck-fast-outline</v-icon>
									{{ 'Envío a domicilio' | lang }}
								</div>
								<div class="pr-2 mb-4">
									<v-card
										class="my-2 pa-4 delivery-card"
										:class="{
											'delivery-card--selected': selection == option,
										}"
										tabindex="none"
										v-for="(option, i) of options.Shipping"
										:key="i"
										@click="selection = option"
									>
										<DeliveryOptionCardContent-Shipping
											:option="option"
											:currency="currency"
											:selected="selection == option"
											:show-cost="true"
										/>
									</v-card>
								</div>
							</template>
							<template v-if="options.PickupPoint && options.PickupPoint.length">
								<div class="py-3 font-2 d-flex align-center">
									<v-icon color="black" class="mr-2">mdi-map-marker-outline</v-icon>
									{{ 'Puntos de entrega' | lang }}
								</div>
								<div class="pickup-points-container pr-4 pr-md-6 mb-4">
									<v-card
										class="my-2 pa-4 delivery-card"
										:class="{
											'delivery-card--selected': selection == option,
										}"
										tabindex="none"
										v-for="(option, i) of options.PickupPoint"
										:key="i"
										@click="selection = option"
									>
										<DeliveryOptionCardContent-PickupPoint
											:option="option"
											:currency="currency"
											:selected="selection == option"
											:show-cost="true"
										/>
									</v-card>
								</div>
							</template>
						</v-radio-group>

						<CardLayout>
							<div class="font-weight-bold">
								{{ '¿Quién va a recibir el producto?' | lang }}
							</div>
							<div class="d-flex py-1">
								<TextField v-model="receiver.firstname" label="Nombre" />
								<div class="mx-3"></div>
								<TextField v-model="receiver.lastname" label="Apellido" />
							</div>
							<div class="py-1" style="width: 250px">
								<TextField v-model="receiver.idNumber" label="Número de documento" />
							</div>
						</CardLayout>
						<div class="d-flex justify-end">
							<Button :disabled="!canSubmit" @click="submit()" color="link"> Siguiente </Button>
						</div>
					</div>
				</div>
			</div>
		</Validator>
		<AddressDialog
			v-model="dialog"
			@addressSelected="onAddressSelected"
			:title="$lang('Seleccioná tu dirección')"
			:countries-filter="countriesFilter"
		/>
	</CheckoutStepLayout>
</template>

<style scoped lang="scss">
.delivery-card {
	border-left: 4px solid transparent;
	&--selected {
		border-left: 4px solid var(--link);
	}
}
.pickup-points-container {
	overflow-y: auto;
	max-height: 420px;
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		padding: 20px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #bbb;
		border-radius: 8px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #aaa;
	}
}
</style>
