module.exports = {
	default: [
		'app/*',
		'common/*',
		'login/*',
		'cart-drawer/*',
		'product-card/*',
		'product-card-image/*',
		'@/media/*',
		'@/vuetify-common/comp_Button',
		'@/vuetify-common/comp_SafeLink',
		'@/vuetify-common/comp_TextField',
		'@/vuetify-common/comp_Validator',
	],
	dpc: 'dynamic-pages-components/*',
	order: 'order/*',
	checkout: [
		'checkout/*',
		'checkout/common/*',
		'checkout/steps/*',
		'checkout/confirm-cards/*',
		'@/vuetify-common/*',
	],
	shoplist: 'shop-list-view/*',
	product: ['product-page-view/*', 'product-upsells-view/*', '@/vuetify-common/comp_Select'],
	account: ['account/*', '@/vuetify-common/comp_MagicButton'],
	notfoundpage: ['not-found-page/*'],
	repentance: ['repentance/*'],
}
